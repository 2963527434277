import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingEnum, MovementType } from "../../enum/common";
import { Movement } from "../../interfaces/movement.interface";
import { GetMovementParams } from "../../interfaces/common";
import { getMovements } from "../../helpers/movement";
import { MONTHS_BEFORE_NOW } from "../../pages/Dashboard";
import moment from "moment";

const listHash: {
  [key in MovementType]:
    | keyof Pick<MovementsState, "expensesList">
    | keyof Pick<MovementsState, "incomesList">
    | keyof Pick<MovementsState, "paymentsList">;
} = {
  [MovementType.Income]: "incomesList",
  [MovementType.Expense]: "expensesList",
  [MovementType.Salary]: "paymentsList",
};
interface MovementsState {
  paymentsList: Movement[];
  incomesList: Movement[];
  expensesList: Movement[];
  incomesDateRange: {
    fromDate: number;
    toDate: number;
  };
  paymentsDateRange: {
    fromDate: number;
    toDate: number;
  };
  expensesDateRange: {
    fromDate: number;
    toDate: number;
  };
  incomesLoading: LoadingEnum;
  paymentsLoading: LoadingEnum;
  expensesLoading: LoadingEnum;
}

const initialState: MovementsState = {
  paymentsList: [],
  incomesList: [],
  expensesList: [],
  incomesDateRange: {
    fromDate: moment().startOf("year").valueOf(),
    toDate: moment().endOf("day").valueOf(),
  },
  paymentsDateRange: {
    fromDate: moment().startOf("year").valueOf(),
    toDate: moment().endOf("day").valueOf(),
  },
  expensesDateRange: {
    fromDate: moment().startOf("year").valueOf(),
    toDate: moment().endOf("day").valueOf(),
  },
  incomesLoading: LoadingEnum.Idle,
  paymentsLoading: LoadingEnum.Idle,
  expensesLoading: LoadingEnum.Idle,
};

export const getIncomesList = createAsyncThunk(
  "incomes/get",
  async ({ params, token }: { params: GetMovementParams; token: string }) =>
    await getMovements({ ...params, movementType: MovementType.Income }, token)
);

export const getPaymentsList = createAsyncThunk(
  "payments/get",
  async ({ params, token }: { params: GetMovementParams; token: string }) =>
    await getMovements({ ...params, movementType: MovementType.Salary }, token)
);

export const getExpensesList = createAsyncThunk(
  "expenses/get",
  async ({ params, token }: { params: GetMovementParams; token: string }) =>
    await getMovements({ ...params, movementType: MovementType.Expense }, token)
);

export const movementsSlice = createSlice({
  name: "movements",
  initialState,
  reducers: {
    addMovement: (state: MovementsState, action: PayloadAction<Movement>) => {
      const { movementType } = action.payload;

      const list = listHash[movementType];

      const newList = [...state[list], action.payload].sort(
        (a, b) => b.date - a.date
      );
      state[list] = newList;
    },
    updateMovement: (
      state: MovementsState,
      action: PayloadAction<Movement>
    ) => {
      const { _id, movementType } = action.payload;
      let index = -1;

      const list = listHash[movementType];

      index = state[list].findIndex((inc) => inc._id === _id);
      if (index === -1) {
        return;
      }
      state[list][index] = {
        ...state[list][index],
        ...action.payload,
      };
    },
    deleteMovement: (
      state: MovementsState,
      action: PayloadAction<{
        id: string;
        movementList: "incomesList" | "paymentsList" | "expensesList";
      }>
    ) => {
      const { id, movementList } = action.payload;

      const index = state[movementList].findIndex((mov) => mov._id === id);

      if (index === -1) {
        return;
      }

      state[movementList].splice(index, 1);
    },
    updateDateRange: (
      state: MovementsState,
      action: PayloadAction<{
        name: "fromDate" | "toDate";
        value: number;
        listRange:
          | "incomesDateRange"
          | "paymentsDateRange"
          | "expensesDateRange";
      }>
    ) => {
      const { name, value, listRange } = action.payload;
      state[listRange][name] = value;
    },
  },
  extraReducers(builder) {
    builder.addCase(getIncomesList.pending, (state: MovementsState) => {
      state.incomesLoading = LoadingEnum.Pending;
    });
    builder.addCase(
      getIncomesList.fulfilled,
      (state: MovementsState, action: PayloadAction<Movement[]>) => {
        state.incomesLoading = LoadingEnum.Success;
        state.incomesList = action.payload;
      }
    );
    builder.addCase(getIncomesList.rejected, (state: MovementsState) => {
      state.incomesLoading = LoadingEnum.Error;
    });

    builder.addCase(getPaymentsList.pending, (state: MovementsState) => {
      state.paymentsLoading = LoadingEnum.Pending;
    });
    builder.addCase(
      getPaymentsList.fulfilled,
      (state: MovementsState, action: PayloadAction<Movement[]>) => {
        state.paymentsLoading = LoadingEnum.Success;
        state.paymentsList = action.payload;
      }
    );
    builder.addCase(getPaymentsList.rejected, (state: MovementsState) => {
      state.paymentsLoading = LoadingEnum.Error;
    });

    builder.addCase(getExpensesList.pending, (state: MovementsState) => {
      state.expensesLoading = LoadingEnum.Pending;
    });

    builder.addCase(
      getExpensesList.fulfilled,
      (state: MovementsState, action: PayloadAction<Movement[]>) => {
        state.expensesLoading = LoadingEnum.Success;
        state.expensesList = action.payload;
      }
    );

    builder.addCase(getExpensesList.rejected, (state: MovementsState) => {
      state.expensesLoading = LoadingEnum.Error;
    });
  },
});

export default movementsSlice.reducer;

export const { addMovement, updateMovement, deleteMovement, updateDateRange } =
  movementsSlice.actions;
